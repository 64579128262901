import { useTranslation } from "react-i18next";

// Helpers
import { capitalizeWords } from "@/_helpers/text_functions";
import { formatFileSize } from "@/_helpers/number_functions";

// Services and interfaces
import { IFile } from "@/interfaces/file/file";

// Styles
import { Pdf, Video, Image, Unknown } from "@carbon/icons-react";

// Components
import { ColDef } from "@/components/datatable";
import FileMenu from "@/pages/vault/files/file_menu";
import { Box } from "@mui/material";


const useFileTableHeaders = (): ColDef<IFile>[] => {

    const { t } = useTranslation();

    const getFileIcon = (mimeType: string) => {
        if (mimeType.startsWith('video/'))
            return <Video size={16} />
        else if (mimeType.startsWith('image/'))
            return <Image size={16} />;
        else if (mimeType == 'application/pdf')
            return <Pdf size={16} />;
        else 
            return <Unknown />;
        // switch (fileType) {
        //     case FileExtension.MP4:
        //     case FileExtension.MOV:
        //     case FileExtension.MPEG:
        //     case FileExtension.MPG:
        //         return <Video size={16} />;
        //     case FileExtension.JPEG:
        //     case FileExtension.PNG:
        //         return <Image size={16} />;
        //     case FileExtension.PDF:
        //         return <Pdf size={16} />;
        //     default:
        //         return <Unknown />;
        // }
    };

    const headers: ColDef<IFile>[] = [
        {
            field: 'name',
            headerName: t('components.dataTable.headers.name'),
            width: '30%',
            sortable: true,
            render: (row: IFile) => 
                <Box display="flex" alignItems="center">
                    {getFileIcon(row.mime_type)}
                    <span style={{marginLeft: '8px'}}>{capitalizeWords(row.name)}</span>
                </Box>
        },
        {
            field: 'created_at',
            headerName: t('components.dataTable.headers.dateAdded'),
            width: '15%',
            render: (row: IFile) => new Date(row.created_at).toLocaleDateString()
        },
        {
            field: 'media_type',
            headerName: t('components.dataTable.headers.mediaType'),
            width: '15%',
            render: (row: IFile) => row.mime_type.split('/')[1]?.toLocaleUpperCase() ?? 'UNKNOWN'
        },
        {
            field: 'size',
            headerName: t('components.dataTable.headers.size'),
            width: '20%',
            render: (row: IFile) => formatFileSize(row.size)
        },
        {
            field: 'actions',
            headerName: '',
            width: '6%',
            render: (row: IFile) => <FileMenu row={row} />
        }
    ];

    return headers;
}

export default useFileTableHeaders;