import { FC } from "react";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm, useFormContext } from "react-hook-form";

// Services and interfaces
import { IFolder } from "@/interfaces/folder/folder";
import { folderFormSchema, IFolderForm } from "@/interfaces/folder/folder_form";

// Components
import { Box } from "@mui/material";
import Modal from "@/components/modal/modal";
import TextInput from "@/components/text_input";


interface _FolderModalProps {
    open: boolean;
    onClose: () => void;
    folder?: IFolder;
    isLoading?: boolean;
    onSave: (data: IFolderForm) => void;
}

const FolderModal: FC<_FolderModalProps> = ({
    open,
    onClose,
    folder,
    isLoading,
    onSave
}) => {

    const { t } = useTranslation();

    const formMethods = useForm<IFolderForm>({
        resolver: zodResolver(folderFormSchema),
        mode: 'onBlur',
        defaultValues: {
            name: folder?.name ?? '',
            description: folder?.description ?? '',
        }
    });

    const handleSave = (data: IFolderForm) => {
        onSave(data);
    }
    
    return (
        <Modal
            open={open}
            onClose={formMethods.formState.isDirty ? undefined : onClose}
            title={t(folder ? 'modals.folder.title_edit' : 'modals.folder.title_add')}
            children={
                <FormProvider {...formMethods}>
                    <FolderForm />
                </FormProvider>}
            action1={{
                label: t('components.buttons.save'),
                loading: isLoading,
                disabled: !formMethods.formState.isDirty,
                onClick: formMethods.handleSubmit(handleSave)
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isLoading,
                onClick: onClose
            }}
            />
    )
}

export default FolderModal


const FolderForm: FC = () => {

    const { t } = useTranslation();
    const { control } = useFormContext<IFolderForm>();

    return (
        <Box display="flex" flexDirection="column" rowGap="16px" minWidth="640px">
            
            <TextInput
                control={control}
                name="name"
                label={t('inputs.titles.folderName')}
                maxLength={40}
                placeHolder={t('inputs.placeholders.folderName')}
                />

            <TextInput
                control={control}
                name="description"
                size="textarea"
                label={t('inputs.titles.folderDescription')}
                maxLength={1000}
                placeHolder={t('inputs.placeholders.folderDescription')}
                minRows={4}
                />

        </Box>
    )
}