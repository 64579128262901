import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";
import { downloadFile } from "@/_helpers/file_functions";

// Services and interfaces
import { Options } from "@/components/menu";
import { IFile } from "@/interfaces/file/file";
import { ApiError } from "@/interfaces/api/error";
import { useDeleteFileMutation, useMoveFileMutation } from "@/repositories/file";
import { MoveFileForm } from "@/interfaces/file/move_file_form";

// Components
import Box  from "@mui/material/Box";
import MoveModal from "./move_modal";
import DeleteModal from "@/pages/vault/components/delete_modal";

import OverflowMenu from "@/components/overflow_menu";
import useBulkActions from "@/components/datatable/use_bulk_actions";


interface _FileMenuProps {
    row: IFile;
}

const FileMenu: FC<_FileMenuProps> = ({
    row
}) => {

    const { t } = useTranslation();
    const { id } = useParams();

    const [open, setOpen] = useState<string|null>(null);
    const { showBulkActions } = useBulkActions();

    const disableMenu = showBulkActions
    const options: Options[] = [
        // {name: t('components.menuItems.rename'), action: () => setOpen('rename')},
        {name: t('components.menuItems.move'), action: () => setOpen('move')},
        {name: t('components.menuItems.download'), action: () => downloadFile(row.link, row.name)}
    ]

    const [deleteFile, { isLoading: isDeleting }] = useDeleteFileMutation(); 
    const [moveFile, { isLoading: isMoving }] = useMoveFileMutation(); 

    const handleMove = (data: MoveFileForm) => {
        moveFile({id: id!, file_id: row.uuid, data}).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.file.moved.title'), 
                message: <Trans 
                    i18nKey="notifications.file.moved.message" 
                    components={{bold: <strong />}}
                    values={{name: row.name}}
                />
            });
            setOpen(null);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    const handleDelete = () => {
        deleteFile({id: id!, file_id: row.uuid}).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.file.deleted.title', {count: 1}), 
                message: <Trans 
                    i18nKey="notifications.file.deleted.message" 
                    values={{name: row.name, count: 1}}
                    components={{bold: <strong />}}
                />
            });
            setOpen(null);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    return (
        <Box display="flex" justifyContent="flex-end">
            <OverflowMenu 
                position={{horizontal: 'right', vertical: 'bottom'}}
                options={options}
                deleteLabel={t('components.menuItems.delete')}
                // disableDelete={disableMenu || disableDelete}
                disabled={disableMenu}
                onDelete={() => setOpen('delete')}
                />
            {open === 'move' && <MoveModal
                open={open === 'move'}
                onClose={() => setOpen(null)}
                file={row}
                isLoading={isMoving}
                onMove={handleMove}
            />}
            {open === 'delete' && <DeleteModal
                type="file"
                open={open === 'delete'}
                onClose={() => setOpen(null)}
                name={row.name}
                isLoading={isDeleting}
                onDelete={handleDelete}
            />}
            
        </Box>
    )
}

export default FileMenu;