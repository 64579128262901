import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Helpers
import showToast from '@/_lib/toast';

// Services and interfaces
import { ApiError } from '@/interfaces/api/error';
import { useGetFolderQuery } from '@/repositories/folder';

// Styles
import { Add } from '@carbon/icons-react';

// Components
import { Container, Box } from '@mui/material';
import DataTable from '@/components/datatable';
import useFileTableHeaders from './file_headers';
import DeleteModal from '@/pages/vault/components/delete_modal';
import useBulkActions from '@/components/datatable/use_bulk_actions';
import FileModal from './file_modal';
import { useDeleteFilesMutation } from '@/repositories/file';
import NoFiles from './no_files';

const FolderPage: FC = () => {

    const { id } = useParams();
    const { t } = useTranslation();
    const headers = useFileTableHeaders();
    const { selected, handleSetSelected, handleSetShowBulkActions } = useBulkActions();
    
    const [open, setOpen] = useState<string|null>(null);

    const { data: folder, isLoading} = useGetFolderQuery(id!);

    const [deleteFolders, { isLoading: isDeletingMany }] = useDeleteFilesMutation();
    const handleDeleteMany = () => {
        deleteFolders({ id: id!, file_ids: selected}).unwrap().then(() => {
            showToast({type: 'success', title: t('notifications.file.deleted.title', {count: selected.length})});
            setOpen(null);
            handleSetShowBulkActions(false);
            handleSetSelected([]);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }


    return (
        <Box height='100%' overflow='auto'>
        <Container sx={{padding: '24px 0'}}>

            <DataTable
                data={folder?.files ?? []}
                columns={headers}
                dataKey="uuid"
                filterKeys={["name"]}
                hideHeader
                localPagination
                filter={{
                    showSelect: true,
                    button: {
                        label: t('components.buttons.addFile'),
                        icon: <Add />,   
                        onClick: () => setOpen('add')          
                    }
                }}
                bulkActions={{
                    action1: {
                        label: t('components.buttons.delete'),
                        onClick: () => setOpen('deleteMany')
                    }
                }}
                noDataMessage={<NoFiles folder={folder!} message={t('components.dataTable.noData.noFiles')} />}
                noDataFoundMessage={<NoFiles folder={folder!} />}
                isLoading={isLoading}
            />

            {open === 'deleteMany' && <DeleteModal
                type="file"
                open={open === 'deleteMany'}
                onClose={() => setOpen(null)}
                name={folder?.files?.find(f => f.uuid === selected[0])?.name ?? ''}
                count={selected.length}
                isLoading={isDeletingMany}
                onDelete={handleDeleteMany}
            />}

            {open === 'add' && <FileModal
                open={open === 'add'}
                folder={folder!}
                onClose={() => setOpen(null)}
                />}

        </Container>
        </Box>
    )
}

export default FolderPage;
