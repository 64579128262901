import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';

// Components
import { Box } from '@mui/material';
import SecondaryToolbar from '@/components/secondary_toolbar';


const VaultPage: FC = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const isFolder = location.pathname.includes('folders/');

    return (
        <Box display="flex" flexDirection="column" height="calc(100vh - 40px)" sx={{overflow: 'hidden'}}>

            <SecondaryToolbar 
                title={t('pages.vault.title')}
                onBack={isFolder ? () => navigate('/vault') : undefined}
                />

            <Outlet />
            
        </Box>
    )
}

export default VaultPage;