import { FC, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { Options } from "@/components/menu";
import { ApiError } from "@/interfaces/api/error";
import { IFolder } from "@/interfaces/folder/folder";
import { IFolderForm } from "@/interfaces/folder/folder_form";
import { useDeleteFolderMutation, useUpdateFolderMutation } from "@/repositories/folder";

// Components
import { Box } from "@mui/material";
import FolderModal from "./folder_modal";
import DeleteModal from "@/pages/vault/components/delete_modal";
import OverflowMenu from "@/components/overflow_menu";
import useBulkActions from "@/components/datatable/use_bulk_actions";


interface _FolderMenuProps {
    row: IFolder;
}

const FolderMenu: FC<_FolderMenuProps> = ({
    row
}) => {

    const { t } = useTranslation();
    const [open, setOpen] = useState<string|null>(null);
    const { showBulkActions } = useBulkActions();
    const [updateFolder, { isLoading: isUpdating }] = useUpdateFolderMutation();
    const [deleteFolder, { isLoading: isDeleting }] = useDeleteFolderMutation();

    const disableMenu = showBulkActions
    const options: Options[] = [
        {name: t('components.menuItems.edit'), action: () => setOpen('edit')},
        // {name: t('components.menuItems.copyTemplate'), disabled: disableMenu, action: () => setOpen('copy')},
        // {name: t('components.menuItems.makeDefault'), disabled: disableMakeDefault, action: () => setOpen('default')}
    ]

    const handleUpdate = (data: IFolderForm) => {
        updateFolder({id: row.id, data: data}).unwrap().then((folder) => {
            showToast({
                type: 'success', 
                title: t('notifications.folder.updated.title'), 
                message: t('notifications.folder.updated.message', {name: folder.name})
            });
            setOpen(null);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    const handleDelete = () => {
        deleteFolder(row.id).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.folder.deleted.title', {count: 1}), 
                message: <Trans 
                    i18nKey="notifications.folder.deleted.message" 
                    values={{name: row.name, count: 1}}
                    components={{bold: <strong />}}
            />
            });
            setOpen(null);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    return (
        <Box display="flex" justifyContent="flex-end">
            <OverflowMenu 
                position={{horizontal: 'right', vertical: 'bottom'}}
                options={options}
                deleteLabel={t('components.menuItems.delete')}
                // disableDelete={disableMenu || disableDelete}
                disabled={disableMenu}
                onDelete={() => setOpen('delete')}
                />
            {open === 'edit' && <FolderModal
                open={open === 'edit'}
                onClose={() => setOpen(null)}
                folder={row}
                isLoading={isUpdating}
                onSave={handleUpdate}
                />}
            {open === 'delete' && <DeleteModal
                type="folder"
                open={open === 'delete'}
                onClose={() => setOpen(null)}
                name={row.name}
                isLoading={isDeleting}
                onDelete={handleDelete}
                />}
            
        </Box>
    )
}

export default FolderMenu;