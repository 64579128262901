import { z } from "zod";
import i18next from "i18next";
import ruleset from "@/_helpers/ruleset";
import { IExerciseMetrics } from "./exercise";
import { t } from "i18next";

export interface IExerciseForm {
    name: string;
    type: string;
    metrics?: IExerciseMetrics|null;
    category: string;
    bodypart: string;
    machine_type: string;
    machine_brand?: string;
    instructions?: string;
    video_link?: string;
    media_link?: string|URL|undefined;
    media_file?: string|URL|undefined;
}

const exerciseMetricsSchema = z.object({
    weight: z.boolean(),
    reps: z.boolean(),
    duration: z.boolean(),
    distance: z.boolean(),
    calories: z.boolean()
}, {message: i18next.t('inputs.errors.required')});

export const exerciseFormSchema = z.object({
    name: ruleset.required,
    type: ruleset.select,
    metrics: exerciseMetricsSchema,
    category: ruleset.select,
    bodypart: ruleset.select,
    machine_type: ruleset.selectOptional,
    machine_brand: ruleset.selectOptional,
    instructions: ruleset.stringOptional,
    media_link: ruleset.video,
    //NOTE - temp fix to remove ruleset.vimeoLink, ruleset.videolink
    video_link: z.union([ruleset.youTubeLink1, ruleset.youTubeLink2, z.literal('')])
}).superRefine((data, ctx) => {
    if (data.category === 'machine') {
      if (!data.machine_type) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['machine_type'],
          message: t('inputs.errors.machineTypeRequired'),
        });
      }
      if (!data.machine_brand) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['machine_brand'],
          message: t('inputs.errors.machineBrandRequired'),
        });
      }
    }
  });

  


