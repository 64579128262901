import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";

// Constants
import { exerciseTypeOptions, exerciseCardioMetricOptions, exerciseMetricOptions, exerciseBodypartOptions, exerciseCategoryOptions, exerciseMachineTypeOptions, exerciseMachineBrandOptions } from "@/_constants/exercise";

// Interfaces
import { IExerciseForm } from "@/interfaces/exercise/exercise_form";

// Components
import { Box } from "@mui/material";
import TextInput from "@/components/text_input";
import SelectInput from "@/components/select_input";
import FileUploader from "@/components/file_uploader";


interface ExerciseFormProps {
    libraryType?: string;
}

const ExerciseForm: FC<ExerciseFormProps> = ({
    libraryType = 'custom'
}) => {

    const { t } = useTranslation();
    const { control, formState, setValue, clearErrors } = useFormContext<IExerciseForm>();
    const { errors } = formState;

    const type = useWatch({ control, name: 'type' });
    const category = useWatch({ control, name: 'category' });
    const video_link = useWatch({ control, name: 'video_link' });
    const media_link = useWatch({ control, name: 'media_link' });

    const isMachine = category == 'machine';
    const hasValidVideo = video_link && !errors.video_link;

    useEffect(() => {
        if (media_link && video_link) {
            setValue('video_link', '', { shouldDirty: true });
            clearErrors('video_link'); // FIXME: This is why errors arent showing. What is this for?
        }        
    }, [media_link, video_link, setValue, clearErrors]);

    return (
        <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap={3} rowGap="12px" minHeight={400} width={1000}>

            <Box display="flex" flexDirection="column">

                <TextInput 
                    name="name"
                    control={control}
                    maxLength={150}
                    label={t('inputs.titles.exerciseName')}
                    placeHolder={t('inputs.placeholders.exerciseName')}
                    disabled={libraryType !== 'custom'}
                    />

                {/* Type and metrics */}
                <Box display="flex" columnGap="16px">

                    <SelectInput 
                        name="type"
                        control={control}
                        label={t('inputs.titles.type')}
                        items={exerciseTypeOptions}
                        itemKey="id"
                        disabled={libraryType !== 'custom'}
                        />

                    <SelectInput 
                        name="metrics"
                        control={control}
                        label={t('inputs.titles.trackingFields')}
                        items={type === 'cardio' ? exerciseCardioMetricOptions : exerciseMetricOptions}
                        itemKey="id"
                        disabled={libraryType !== 'custom'}
                        />

                </Box>

                {/* Bodypart and category */}
                <Box display="flex" columnGap="16px">

                    <SelectInput 
                        name="bodypart"
                        control={control}
                        label={t('inputs.titles.primaryFocus')}
                        items={exerciseBodypartOptions}
                        itemKey="value"
                        disabled={libraryType !== 'custom'}
                        />

                    <SelectInput 
                        name="category"
                        control={control}
                        label={t('inputs.titles.category')}
                        items={exerciseCategoryOptions}
                        itemKey="value"
                        disabled={libraryType !== 'custom'}
                        />

                </Box>

                {/* Machine */}
                {isMachine && <Box display="flex" columnGap="16px">

                    <SelectInput 
                        name="machine_type"
                        control={control}
                        label={t('inputs.titles.machineType')}
                        items={exerciseMachineTypeOptions}
                        itemKey="value"
                        disabled={libraryType !== 'custom'}
                        />

                    <SelectInput 
                        name="machine_brand"
                        control={control}
                        label={t('inputs.titles.machineBrand')}
                        items={exerciseMachineBrandOptions}
                        itemKey="value"
                        disabled={libraryType !== 'custom'}
                        />

                </Box>}

                <Box display="flex" flexDirection="column" flexGrow={1} />

                {/* Instructions */}
                <TextInput
                    name="instructions"
                    control={control}
                    size="textarea"
                    label={t('inputs.titles.instructions')}
                    placeHolder={t('inputs.placeholders.exerciseInstructions')}
                    minRows={4}
                    disabled={libraryType !== 'custom'}
                    />

            </Box>

            <Box display="flex" flexDirection="column">

                <Box display="flex" columnGap="16px" alignItems="top">

                    {!media_link && <>
                        <TextInput
                            name="video_link"
                            control={control}
                            label={t('inputs.titles.videoLink')}
                            //NOTE - temp helper text until vimeo issue resolved on mobile
                            placeHolder={t('inputs.placeholders.exerciseVideoLinkTemp')}
                            //NOTE - temp helper text until vimeo issue resolved on mobile
                            helperText={t('inputs.helperText.exerciseVideoTemp')}
                            disabled={libraryType !== 'custom'}
                        />

                        <span className="label-text-02" style={{marginTop: '28px'}}>{t('pages.library.exercises.or')}</span>
                    </>}

                    <FileUploader
                        name="media_link"
                        control={control}
                        label={t('inputs.titles.videoFile')}
                        disabled={libraryType !== 'custom'}
                    />

                </Box>

                <Box display="flex" flexDirection="column" flexGrow={1} />

                {hasValidVideo && !media_link && <VideoEmbed url={video_link} />}
                {!video_link && media_link && <VideoEmbed url={media_link as string} />}

            </Box>

        </Box>
    )
}

export default ExerciseForm;

interface VideoEmbedProps {
    url: string;
}

const VideoEmbed: FC<VideoEmbedProps> = ({ url }) => {

    if (!url) return null;

    const mediaUrl = import.meta.env.VITE_MEDIA_URL;
    const youTube1Regex = /^(https:\/\/)?(www\.)?(youtube\.com\/watch\?v=)([A-Za-z0-9\_-]){11}(\/)?$/;
    const youTube2Regex = /^https:\/\/youtu\.be\/[A-Za-z0-9_-]{11}(\?si=[A-Za-z0-9_-]+)?$/;
    const vimeoRegex = /^(https:\/\/(www\.)?vimeo\.com\/(channels\/[A-Za-z0-9_-]+\/)?[0-9]{9})?$/;
    const mediaRegex = new RegExp(mediaUrl, 'i');
    const isBlob = url.startsWith('blob:');

    if (youTube1Regex.test(url) || youTube2Regex.test(url)) {
        const videoId = url.split('v=')[1] || url.split('youtu.be/')[1];
        const embedUrl = `https://www.youtube.com/embed/${videoId}`;
        return (
            <Box display="flex" marginBottom="12px" width='100%'>
                <iframe width='100%' style={{aspectRatio: '16/9'}} src={embedUrl} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Box>
        );
    } else if (vimeoRegex.test(url)) {
        const videoId = url.split('/').pop();
        const embedUrl = `https://player.vimeo.com/video/${videoId}`;
        return (
            <Box display="flex" marginBottom="12px" width='100%'>
                <iframe width='100%' style={{aspectRatio: '16/9'}} src={embedUrl}  frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
            </Box>
        );
    } else if (mediaRegex.test(url)) {
        return (
            <video width='100%' controls style={{backgroundColor: 'var(--black)', marginBottom: '12px', aspectRatio: '16/9'}}>
              <source src={url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          );
    } else if (isBlob) {
        return (
            <video width='100%' controls style={{backgroundColor: 'var(--black)', marginBottom: '12px', aspectRatio: '16/9'}}>
              <source src={url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
        );
    }
    return null;
};