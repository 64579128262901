import { t } from "i18next";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { useAddFolderMutation } from "@/repositories/folder";
import { IFolderForm } from "@/interfaces/folder/folder_form";

// Styles
import { Add } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import FolderModal from "./folder_modal";
import Button from "@/components/button";


interface _NoFoldersProps {
    message?: string;
    disabled?: boolean;
}

const NoFolders: FC<_NoFoldersProps> = ({
    message = t('components.dataTable.noData.noFoldersFound'),
    disabled = false
}) => {

    const { t } = useTranslation();
    const [open, setOpen] = useState<string|null>(null);
    const [addFolder, { isLoading: isAdding }] = useAddFolderMutation();

    const handleAdd = (data: IFolderForm) => {
        addFolder(data).unwrap().then((folder) => {
            showToast({
                type: 'success', 
                title: t('notifications.folder.created.title'), 
                message: t('notifications.folder.created.message', {name: folder.name})
            });
            setOpen(null);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%" width="100%" paddingBottom="24px">

            <Box height="32px" />
            <span className="body-02-compact" style={{color: 'var(--text-secondary)'}}>{message}</span>
            <Box height="12px" />

            <Box display="flex"justifyContent="center" alignItems="center" columnGap="8px">
                <Button
                    kind="ghost"
                    size="small"
                    label={t('components.buttons.addFolder')}
                    endIcon={<Add />}
                    minWidth={false}
                    disabled={disabled}
                    onClick={() => setOpen('add')}
                    />
                    {open === 'add' && <FolderModal 
                        open={open === 'add'} 
                        onClose={() => setOpen(null)}
                        isLoading={isAdding}
                        onSave={handleAdd} 
                        />}
            </Box>

        </Box>
    )
}

export default NoFolders;